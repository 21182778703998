import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FullCalendarModule } from '@fullcalendar/angular';


import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgCalendarModule } from 'ionic2-calendar';
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule,FullCalendarModule, IonicModule.forRoot(), AppRoutingModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },NgCalendarModule],
  bootstrap: [AppComponent],
})
export class AppModule {

}
