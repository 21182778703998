import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  // {
  //   path: '',
  //   redirectTo: 'home',
  //   pathMatch: 'full'
  // },
  {
    path: 'volunteer-login',
    loadChildren: () => import('./volunteer-login/volunteer-login.module').then( m => m.VolunteerLoginPageModule)
  },
  {
    path: 'volunteer-signup',
    loadChildren: () => import('./volunteer-signup/volunteer-signup.module').then( m => m.VolunteerSignupPageModule)
  },
  {
    path: 'volunteer-calendar',
    loadChildren: () => import('./volunteer-calendar/volunteer-calendar.module').then( m => m.VolunteerCalendarPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'volunteer-dashboard',
    loadChildren: () => import('./volunteer-dashboard/volunteer-dashboard.module').then( m => m.VolunteerDashboardPageModule)
  },
  {
    path: 'volunteer-profile',
    loadChildren: () => import('./volunteer-profile/volunteer-profile.module').then( m => m.VolunteerProfilePageModule)
  },
  {
    path: 'org-signup',
    loadChildren: () => import('./org-signup/org-signup.module').then( m => m.OrgSignupPageModule)
  },
  {
    path: 'org-login',
    loadChildren: () => import('./org-login/org-login.module').then( m => m.OrgLoginPageModule)
  },
  {
    path: 'org/:id',
    loadChildren: () => import('./org/org.module').then( m => m.OrgPageModule)
  },
  {
    path: 'org-dashboard',
    loadChildren: () => import('./org-dashboard/org-dashboard.module').then( m => m.OrgDashboardPageModule)
  },
  {
    path: 'create-event',
    loadChildren: () => import('./create-event/create-event.module').then( m => m.CreateEventPageModule)
  },
  {
    path: 'org-stats',
    loadChildren: () => import('./org-stats/org-stats.module').then( m => m.OrgStatsPageModule)
  },
  {
    path: 'org-manage-events',
    loadChildren: () => import('./org-manage-events/org-manage-events.module').then( m => m.OrgManageEventsPageModule)
  },
  {
    path: 'org-edit-details',
    loadChildren: () => import('./org-edit-details/org-edit-details.module').then( m => m.OrgEditDetailsPageModule)
  },
  {
    path: 'volunteer-hours',
    loadChildren: () => import('./volunteer-hours/volunteer-hours.module').then( m => m.VolunteerHoursPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'reset-password-request',
    loadChildren: () => import('./reset-password-request/reset-password-request.module').then( m => m.ResetPasswordRequestPageModule)
  },
  {
    path: 'volunteer-hours-pdf',
    loadChildren: () => import('./volunteer-hours-pdf/volunteer-hours-pdf.module').then( m => m.VolunteerHoursPdfPageModule)
  },
  {
    path: 'event-search',
    loadChildren: () => import('./event-search/event-search.module').then( m => m.EventSearchPageModule)
  },
  {
    path: 'listings',
    loadChildren: () => import('./listings/listings.module').then( m => m.ListingsPageModule)
  },
  {
    path: 'seed-saving',
    loadChildren: () => import('./seed-saving/seed-saving.module').then( m => m.SeedSavingPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
